import React from 'react'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import ContactForm from '../components/ContactForm'

const Contact = ({ data }) => {
  return (
    <Layout>
      <GatsbySeo
      title='Ota Yhteyttä / Contact Us | Kasinosivu.com'
      description='Lähetä viesti kasinosivu.com :lle'
      
    />
      <Container>
        <PageTitle>Ota yhteyttä / Contact Us</PageTitle>
        <div><center>Email: info@kasinosivu.com</center></div>
        <ContactForm />
      </Container>
    </Layout>
  )
}

export default Contact
